/* master.css */

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

h4, h5, h6 {
    margin: 0.3rem auto;
}

/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-size: cover; 
  background-position: center; 
  background-attachment: fixed; 
} */
