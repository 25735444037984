.banner-container {
    display: flex;
    margin-left: 15vw;
    width: 70vw;
    align-items: center;
    justify-content: space-between;
    background-color: #fdf9f6;
    padding: 1vw 4vw;
    /* margin-right: 2vw; */
}

.banner-image-overlay {
    width: 10vw;
    height: width;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Container for overlaying images */
  }

  .banner-logo-0 {
    width: 40vw;
    background-color: #fdf9f6; 
    border-radius: 50%;
    position: relative;
    transition: transform 0.5s; 
    z-index: 1;
}

  .banner-overlay-logo-0 {
    width: 21vw;
    margin-bottom: 0.75vw;
    position: absolute;
    transition: transform 0.5s; 
    z-index: 2; 
  }

  .banner-logo-1 {
    width: 40vw;
    background-color: #fdf9f6; /* Light green circle */
    border-radius: 50%;
    position: relative;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 1;
    /* top: 1vw; */
    /* left: 0.3vw; */
}

  .banner-overlay-logo-1 {
    width: 30vw;
    border-radius: 50%;
    position: absolute;
    /* top: 6.5vw; */
    /* left: -5.4vw; */
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 2; /* Overlay image */
    opacity: 1;
  }

  .banner-logo-2 {
    width: 40vw;
    background-color: #fdf9f6; /* Light green circle */
    border-radius: 50%;
    position: relative;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 1;
}

  .banner-overlay-logo-2 {
    width: 25.5vw;
    border-radius: 50%;
    position: absolute;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 2; /* Overlay image */
    opacity: 1;
  }

  .banner-logo-3 {
    width: 40vw;
    background-color: #fdf9f6; /* Light green circle */
    border-radius: 50%;
    position: relative;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 1;
}

  .banner-overlay-logo-3 {
    width: 26vw;
    border-radius: 50%;
    position: absolute;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 2; /* Overlay image */
    opacity: 1;
  }

.banner-image-overlay:hover .banner-logo {
    transform: scale(1.2) rotate(360deg); /* Combine transforms */
}
.banner-image-overlay:hover .banner-overlay-logo {
    transform: scale(1.2) rotate(-360deg); /* Combine transforms */
}

.banner-header-text {
    width: 54%;
    /* padding-left: 12%; */
    /* padding-right: 10%; */
    font-size: 1.7vw;
    font-weight: bold;
    color: #333;
    text-shadow: 1px 1px 2px #6d6c6c; 
    text-align: left;
    font-family: 'Libre Baskerville', cursive;

}

