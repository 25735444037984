.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fdf9f6; /* Soft pink background */
    padding: 20px;
  }
  
  .footer-icons {
    display: flex;
    gap: 40px; /* Adjust for spacing between icons */
    margin-bottom: 20px;
  }
  
  .footer-icons a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: color 0.3s;
  }
  
  .footer-icons a:hover {
    color: #000; /* Change color on hover */
  }
  
  .footer-icons svg {
    font-size: 24px; /* Adjust icon size */
    margin-bottom: 5px;
  }
  
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: 20px;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #41badb;
    transition: border-color 0.3s;
  }
  
  .footer-links a:hover {
    color: #017a9b;
    opacity: 0.8; 
    scale: 1.1;
  }