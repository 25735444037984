/* stillworking.css */

.still-working-container {
    display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 10vw;
      margin-right: 5vw;
  }
  .still-working-img {
      display: flex;
      justify-content: center;
      margin: 1rem;
      max-width: 30vw;
      border-radius: 10%;
      transition: transform 0.5s;
  }
  .still-working-header-text {
    font-size: 2.1vw;
    font-weight: bold;
    color: #333;
    text-shadow: 1px 1px 2px #6d6c6c; 
    margin-left: 5vw;
    margin-right: 10vw;
    text-align: left;
    font-family: 'Libre Baskerville', cursive;
  }