/* header.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #fdf9f6; /* Light beige-like background */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4vw 8vw;
    background-color: #fdf9f6;
    
  }
  
.logo-container {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  justify-content: flex-start; /* Aligns them to the left */
}


  .logo-container a {
    text-decoration: none; 
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .image-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Container for overlaying images */
    width: 60px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
  }

  .logo {
    width: 6vw;
    background-color: #fdfaf2; /* Light green circle */
    border-radius: 50%;
    position: relative;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 1;
}

  .overlay-logo {
    width: 3.15vw;
    position: absolute;
    transition: transform 0.5s; /* Add this line for animation */
    z-index: 2; /* Overlay image */
    opacity: 1;
  }

.header-text {
  width: 10vw;
  font-size: 2vw;
  font-weight: bold;
  color: #333;
  /* max-width: 40%; */
  text-align: left;
  /* margin-left: 4vw; */
}

.logo-container:hover .logo {
    transform: scale(1.25) rotate(360deg); /* Rotate clockwise */
}

.logo-container:hover .overlay-logo {
    transform: scale(1.25) /*rotate(-360deg)*/; /* Combine transforms */
}
.logo-container:hover .header-text {
  transform: scale(1.1);
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), /* Horizontal and vertical shadow */
              0 6px 20px rgba(0, 0, 0, 0.19); /* Larger shadow */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition */
}
  
  .nav-links {
    display: flex;
    gap: 2vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  
  .nav-link {
    text-decoration: none;
    font-size: 2.5vw;
    font-weight: bold;
    color: #333;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #000;
    text-decoration: underline;
  }
  
  .language-selector {
    display: flex;
    align-items: center;
    font-size: 2.5vw;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }
  
  .language-selector .arrow-down {
    margin-left: 5px;
    font-size: 2vw;
    transition: transform 0.3s;
  }
  
  .language-selector:hover .arrow-down {
    transform: rotate(180deg); /* Rotate arrow on hover */
  }

  @media (max-width: 4096px) {
    .header-text {
      margin-left: 3vw;
    }
    .language-dropdown li {
      font-size: 1vw;
    }
  }

  @media (max-width: 2048px) {
    .header-text {
      margin-left: 2.5vw;
    }
    .language-selector {
      font-size: 3.6vw;
    }
  
    .language-dropdown li {
      font-size: 1.6vw;
    }
  
    .language-selector .arrow-down {
      font-size: 1.2vw;
    }
  }

  @media (max-width: 1536px) {
    .header-text {
      margin-left: 1.875vw;
    }
    .language-selector {
      font-size: 2.4vw;
    }
  
    .language-dropdown li {
      font-size: 1.6vw;
    }
  
    .language-selector .arrow-down {
      font-size: 1.2vw;
    }
  }

  @media (max-width: 1024px) {
    .header-text {
      margin-left: 0.75vw;
    }
    .language-selector {
      font-size: 2.4vw;
    }
  
    .language-dropdown li {
      font-size: 1.6vw;
    }
  
    .language-selector .arrow-down {
      font-size: 1.2vw;
    }
  }

  @media (max-width: 767px) {
    .header-text {
      margin-left: -0.5vw;
    }
    .language-selector {
      font-size: 1.5vw;
    }
  
    .language-dropdown li {
      font-size: 1.6vw;
    }
  
    .language-selector .arrow-down {
      font-size: 1.2vw;
    }
  }

  @media (max-width: 512px) {
    .header-text {
      margin-left: -2vw;
    }
    .language-selector {
      font-size: 1.2vw;
    }
  
    .language-dropdown li {
      font-size: 1.6vw;
    }
  
    .language-selector .arrow-down {
      font-size: 1.2vw;
    }
  }

  /* Dropdown styles */
.language-selector {
  position: relative; /* Required for dropdown to position correctly */
  display: flex;
  align-items: center;
  font-size: 2.5vw;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  margin-left: 2vw;
}

.language-selector .arrow-down {
  margin-left: 0.5vw;
  font-size: 2vw;
  transition: transform 0.3s;
}

.language-selector:hover .arrow-down {
  transform: rotate(180deg); /* Rotate arrow on hover */
}

.language-dropdown {
  position: absolute;
  top: 100%; /* Position dropdown just below the selector */
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 1vw;
  padding: 0;
  list-style: none;
  margin-top: 0.5vw;
  width: 12vw; /* Adjust the width if necessary */
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.language-dropdown li {
  padding: 1vw;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.language-dropdown li:hover {
  background-color: #f0f0f0;
}

.language-dropdown li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

